<template>
    <div v-if="filterGroup.groupList" class="d-flex flex-wrap templates-list" :key="filterGroup.filterString">
       
        <template v-for="item in filterGroup.groupList">
            {{console.log("Hit for loop",typeof item,item)}}
            <div v-if="typeof item == 'string'" :class="getClass(item)" class="d-flex">{{item}}</div>
            
            <div class="d-flex  flex-wrap templates-list" v-else :class="setEmptyClass(item)" @click="() => {console.log(item)}"> [{{item.caption}}] {{item.shortOperator}} 
                <span><ItemTemplate :item="item" addFormClasses/></span>

            </div>
        </template>
        <slot></slot>
    </div>
</template>

<script setup>
    import ItemTemplate from 'o365.vue.components.InputEditors.Base.Item.vue';
    const props = defineProps({
        filterGroup:Object
    });

    const getClass = (item) =>{
        if(item === "AND") return 'px-1'; 
        if(item === "OR") return 'px-1'; 
        return '';
       
    }
    const isOperator = (item) =>{
       return ['AND','OR'].indexOf(item) > -1
    }

    const setEmptyClass = (item) =>{
        return (item.value??item._value)?"":"empty";
    }

    const change = (pObj)=>{
        props.filterGroup.updateFilterString();
    }

</script>
<style >
    .templates-list >*{
        line-height:1.5!important;
    }
    
    .templates-list{
        align-items: center;
    }
      
    .empty input{
        background-color:cornsilk;
    }

   
  
    .templates-list .dp__main{
        width:unset!important;
    }

</style>